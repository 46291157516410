<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Liste des proprietes</h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-danger mr-1"  @click="datatableInit()">
            <i class="icon-md fas fa-search"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="4">
            <b-form-group :label="filter[1].label+':'">
              <select class="form-control" v-model="filter[1].value">
                <option :value="null">Tout</option>
                <option v-for="(t,i) in params.ProprieteType" :value="t.id" :key="i">{{t.titre.fr}}</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[2].label+':'">
              <select class="form-control" v-model="filter[2].value">
                <option :value="null">Tout</option>
                <option v-for="(t,i) in params.TransactionType" :value="t.id" :key="i">{{t.titre.fr}}</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[3].label+':'">
              <vue-google-autocomplete id="map" classname="form-control" placeholder="Adresse" types="(cities)" v-on:placechanged="getAddressData" country="il"> </vue-google-autocomplete>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[4].label+':'">
              <select class="form-control" v-model="filter[4].value">
                <option :value="null">Tout</option>
                <option v-for="(t,i) in params.Quartier" :value="t.id" :key="i">{{t.titre.fr}}</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[5].label+':'">
              <b-form-input v-model.number="filter[5].value" type="number"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[6].label+':'">
              <b-form-input v-model.number="filter[6].value" type="number"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[8].label+':'">
              <b-form-input v-model.number="filter[8].value" type="number"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[9].label+':'">
              <b-form-input v-model.number="filter[9].value" type="number"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[7].label+':'">
              <select class="form-control" v-model="filter[7].value">
                <option :value="null">Tout</option>
                <option v-for="(t,i) in params.ProprieteEtat" :value="t.id" :key="i">{{t.titre.fr}}</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[10].label+':'">
              <select class="form-control" v-model="filter[10].value">
                <option :value="null">Tout</option>
                <option v-for="(t,i) in params.Utilisation" :value="t.id" :key="i">{{t.titre.fr}}</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[11].label+':'">
              <b-form-input v-model.number="filter[11].value" type="number"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="filter[12].label+':'">
              <b-form-input v-model.number="filter[12].value" type="number"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Liste des proprietes ({{datatable.totalRow}})</h3>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="1">
            <select class="form-control" v-model="datatable.limitPage" @change="datatableInit">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </b-col>
        </b-row>
        <b-table responsive striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
          <template #cell(id)="data">
            <a :href="'/#/propriete/detail/'+data.value">{{ data.value }}</a>
          </template>
          <template #cell(projet.titre)="data">
            <a :href="'/#/projet/detail/'+data.item.projet.id" v-if="data.item.projet.id != 0">{{ data.value }}</a>
            <span v-else>Aucun</span>
          </template>
          <template #cell(client.nom)="data">
            <a :href="'/#/client/detail/'+data.item.client.id">{{ data.value }}</a>
          </template>
          <template #cell(type_id)="data">
            {{params.ProprieteType.find(t => t.id === data.value).titre.fr}}
          </template>
          <template #cell(transaction_type_id)="data">
            {{params.TransactionType.find(t => t.id === data.value).titre.fr}}
          </template>
          <template #cell(status_id)="data">
            {{params.ProprieteStatus.find(t => t.id === data.value).titre.fr}}
          </template>
        </b-table>
        <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
export default {
  data(){
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable:true,
          searchable:true
        },
        {
          key: 'projet.titre',
          label: 'Projet',
          sortable:false,
          searchable:true
        },
        {
          key: 'client.nom',
          label: 'Client',
          sortable:false,
          searchable:true
        },
        {
          key: 'type_id',
          label: 'Type',
          sortable:false,
          searchable:false
        },
        {
          key: 'status_id',
          label: 'Statut',
          sortable:false,
          searchable:false
        },
        {
          key: 'superficie',
          label: 'Superficie',
          sortable:true,
          searchable:false
        },
        {
          key: 'transaction_type_id',
          label: 'Type Trans',
          sortable:true,
          searchable:false
        },
        {
          key: 'prix_transaction',
          label: 'Prix',
          sortable:true,
          searchable:false
        }
      ],
      datatable:{
        currentPage: 1,
        nextPage: 2,
        previousPage: 0,
        limitPage: 20,
        totalRow: 0,
        data: []
      },
      sort:{
        field: 'id',
        value: 'DESC'
      },
      filter:[
        {
            "key":"date_create",
            "label":this.$store.back.getText('date_create'),
            "type":"String",
            "value":null,
            "algo":false
        },
        {
          "key":"type_id",
          "label":this.$store.back.getText('type'),
          "type":"Integer",
          "value":null,
          "algo":false
        },
        {
            "key":"transaction_type_id",
            "label":this.$store.back.getText('type_transaction'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"adresse.locality",
            "label":this.$store.back.getText('ville'),
            "type":"String",
            "value":null,
            "algo":false
        },
        {
            "key":"adresse.quartier_id",
            "label":this.$store.back.getText('quartier'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"min_superficie",
            "label":this.$store.back.getText('superficie_min'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"max_superficie",
            "label":this.$store.back.getText('superficie_max'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"etat_id",
            "label":this.$store.back.getText('etat'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"min_duree",
            "label":this.$store.back.getText('min_duree'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"max_duree",
            "label":this.$store.back.getText('max_duree'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"utilisation_id",
            "label":this.$store.back.getText('utilisation'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"min_prix_transaction",
            "label":this.$store.back.getText('prix_min'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"max_prix_transaction",
            "label":this.$store.back.getText('prix_max'),
            "type":"Integer",
            "value":null,
            "algo":false
        }
      ],
      params:{
        status:[],
        ProprieteType:[],
        ProprieteStatus:[],
        TransactionType:[],
        Quartier:[],
        Utilisation:[],
        ProprieteEtat:[]
      },
      citys:[]
    };
  },
  components: {
    VueGoogleAutocomplete
  },
  watch: {
  },
  beforeMount() {
    if(this.$route.query.query  != undefined){
      var str = this.$route.query.query
      str = decodeURIComponent(str);
      this.filter = JSON.parse(str);
    }
    let params = {
      modules: ["ProprieteType","ProprieteStatus","TransactionType","ProprieteEtat","Quartier","Utilisation"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.ProprieteType = res.data['ProprieteType'];
        this.params.ProprieteStatus = res.data['ProprieteStatus'];
        this.params.TransactionType = res.data['TransactionType'];
        this.params.status = res.data['status'];
        this.params.Quartier = res.data['Quartier'];
        this.params.Utilisation = res.data['Utilisation'];
        this.params.status = res.data['status'];
        this.params.ProprieteEtat = res.data['ProprieteEtat'];
      }
    });
    this.$store.back.ajax('/propriete/citys', null, (res) => {
      if(res.status){
        this.citys = res.data
      }
    })
  },
  methods: {
    getAddressData(addressData) {
      this.filter[3].value = addressData.locality;
    },
    datatableInit: function(){
      var params = {
        filter: this.filter.filter(f => f.algo === true),
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.$store.back.ajax('/propriete/filter', params, (res) => {
        this.datatable = res;
      });
    },
    sortChanged(e){
      this.sort = {
        key: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
  },
  computed: {
  },
  mounted() {
    this.datatableInit();
  }
};
</script>
